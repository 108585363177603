<div class="wrapper">
    <div class="sidebar" data-color="brown" data-active-color="danger">
        <sidebar-cmp></sidebar-cmp>
    </div>
    <div class="main-panel bg-300">
        <navbar-cmp></navbar-cmp>
        <router-outlet></router-outlet>
        <div *ngIf="!isMap()">
            <footer-cmp></footer-cmp>
        </div>
    </div>
</div>
<div class="createdBy">Ky sistem elektronik mirëmbahet nga operatorët ekonomik: Image & Communications Development dhe Infosoft Systems shpk</div>