import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { AuthenticationService } from './authenticationService';
import jwt_decode from "jwt-decode";
import { UserService } from './user-service';


@Injectable()
export class RoleGuardService implements CanActivate {

    constructor(public auth: AuthenticationService, public router: Router, private userService: UserService) { }

    canActivate(route: ActivatedRouteSnapshot): boolean {
        const expectedRole = route.data.expectedRole;
        const roleToShow = route.data.roleToShow
        // decode the token to get its payload
        const tokenPayload: any = this.parseToken();
        if (!this.checkPermission(tokenPayload.permission, expectedRole)) {
            this.router.navigate(['not-found']);
            return false;
        }
        if (!roleToShow) {
            return true
        }
        if (!this.userService.checkForRole(roleToShow)) {
            this.router.navigate(['not-found']);
            return false;
        }

        return true;
    }

    checkPermission(permissions: any[], permission: any[]): boolean {

        if (typeof permissions === "string") {
            permissions = [permissions];
        }
        let allowRoute: boolean = false;
        if (permissions && permissions.length > 0) {
            for (let i = 0; i < permission.length; i++) {
                if (permissions.some(x => x === permission[i]) || permission[i] == '1') {
                    allowRoute = true;
                }
            }
        }

        return allowRoute;

    }
    checkRole(roles: any) {
        let allowRoute: boolean = false;
        const tokenRoles = this.parseToken()['role']
        //if roles passed to route matches the roles the user who is logged in has. If its found a common element between disallowed routes,dont load the route.
        const found = roles?.filter(value => tokenRoles.includes(value));
        if (!found || found.length === 0) {

            return true
        }
        return allowRoute;
    }

    parseToken() {
        const token: any = JSON.parse(localStorage.getItem('currentUser'));
        return jwt_decode(token.access_token);

    }

}