
import { Injectable } from '@angular/core';
import { InstitutionsModel } from 'app/institutions/institutions-model';
import { GetDataService } from './getData.service';

export enum ReportTypes {
  Dite14 = 0, //Raport për paaftësi në punë për sëmundje të përgjithshme që paguhen nga punëdhënësi
  Urgjence = 1, //Raport për paaftësi në punë për sëmundje të përgjithshme që paguhen nga punëdhënësi (Urgjenca)
  PaAftesiKml = 2, // Raport mjekësor për sëmundje që trajtohen nga Komisioni Mjekësor dhe mbulohen nga Sigurimet Shoqërore (kml me 14 ditor)
  SpecialistSpital = 3, //Raport mjekësor për sëmundje që trajtohen nga institucionet me shtretër
  AksidenteSemundjeKml = 4, //Raport për paaftesi të përkohshme në punë, për aksidente në punë dhe sëmundje profesionale (kml pa 14ditor)
  Barrelindje = 5, //Raport për leje barrëlindje
  PuneTeLehta = 6, //Raport për kompensim në rast sëmundje ose shtatëzanie kur ndryshon vendi i punës (kml pa 14ditor)
  VertetimStudent = 7, //Raport mjekësor për nxënësit dhe studentët
  Dite14Spital = 8, //Raport për paaftësi në punë për sëmundje të përgjithshme që paguhen nga punëdhënësi
  AksidenteSemundjeSpital = 9, //Raport për paaftesi të përkohshme në punë, për aksidente në punë dhe sëmundje profesionale spital
  Dite14KuruarJashte = 10 //Raport për paaftesi në punë për sëmundje të përgjithshme që paguhen nga punëdhënësi
}
export enum Roles {
    Administrator = 10,
    MjekFamilje = 11,
    MjekKml = 12,
    MjekUrgjence = 13,
    MjekKonsultor = 14,
    MjekSpecialistPoliklinike = 15,
    MjekSpecialistSpital = 16,
    Drejtor=17,
    NJVKSH = 18,
    DROSHKSH = 19,
    OSHKSH = 20,
    Support = 21,
    Statistike = 22,
    Audit = 23
}

export enum SpecialityType {
  Okulist = 1,
  Neurogol = 2,
  Psikiater = 3,
  Otorinolaringolog = 4
}

export enum SignedStatus {
  PaFirmosur = 0,
  FirmosurPjeserisht = 1,
  Firmosur = 2,
  Refuzuar = 3
}
export enum TypeToSign {
  AnamnesisCertificate = 1,
  GunsCertificate = 2,
  Report = 3
}

export enum StatusSigned{
  PANENSHKRUAR='Pa nënshkruar',
  NENSHKRUAR='Nënshkruar'
}


@Injectable({ providedIn: 'root' })
export class EnumService {
  constructor(private dataService: GetDataService) { }
  getGender() {
    return [{ key: '0', value: 'Mashkull' }, { key: '1', value: 'Femër' }]
  }
  getStudentType() {
    return [{ key: 1, value: 'Student' }, { key: 2, value: 'Nxënës' }]
  }
  getMarried() {
    return [{ key: 'SIN', value: 'Beqar' }, { key: 'MAR', value: 'Martuar' }, { key: 'WID', value: 'I VE' }, { key: 'DIV', value: 'Divorcuar' }]
  }
  getInstitutions(id): Promise<InstitutionsModel> {
    let institution: InstitutionsModel;
    return new Promise(resolve => {
      this.dataService.getDataById(`Institution/getById/${id}`).subscribe(
        (data: InstitutionsModel) => {
          resolve(data);
        }
      );
    });
  }
  getDiagnosis(id: string): Promise<any> {
    let diagnose: any;
    return new Promise(resolve => {
      this.dataService.getDataById(`Diagnose/getById/${id}`).subscribe(
        (data: any) => {
          diagnose = data
          resolve(data);
        }
      );
    });
  }
  getPregnancyType(): any {
    return [{ key: 1, value: 'Paralindje' },{ key: 3, value: 'Paslindje (63 Ditë)' }, { key: 4, value: 'Kujdes për fëmijën' }]
  }
  getCHildrens(): any {
    return [{ key: 1, value: 'Barrë me një fëmijë' }, { key: 2, value: 'Barrë me shumë fëmijë' }]
  }
  getSpecialities(): any {
    return [{ key: 'Okulist', value: 'Okulist' }, { key: 'Neurolog', value: 'Neurolog' }, { key: 'Psikiater', value: 'Psikiater' }, { key: 'Otorinolaringolog', value: 'Otorinolaringolog' }]
  }

  getCertificateOrReportEnum(): any {
    return [
      { key: 1, value: 'Certefikatë anamneze' },
      { key: 2, value: 'Vërtetim për armë' },
      { key: 3, value: 'Raport mjekësor' },
      { key: 4, value: 'Vërtetim për punë' },
    ]
  }

  getApplicationForms(): any{
    return [{key:1, value: "PAK"}]
  }
  /**
 * Get month name by month number
 * @param month Integer
 * @returns String
 */
  public getMonthName(month: number): string {
    switch (month) {
      case 1: {
        return 'Janar';
      }
      case 2: {
        return 'Shkurt'
      }
      case 3: {
        return 'Mars'
      }
      case 4: {
        return 'Prill'
      }
      case 5: {
        return 'Maj'
      }
      case 6: {
        return 'Qershor'
      }
      case 7: {
        return 'Korrik'
      }
      case 8: {
        return 'Gusht'
      }
      case 9: {
        return 'Shtator'
      }
      case 10: {
        return 'Tetor'
      }
      case 11: {
        return 'Nëntor'
      }
      case 12: {
        return 'Dhjetor'
      }
      default: {
        break;
      }
    }
  }

}

export enum IsApproriateEnum {
  IPershtatshem = 1,
  IPapershtatshem = 2
}