import { FormComponentComponent } from './form-component/form-component.component';
import { GridComponent } from './grid/grid.component';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ComponentsRoutes } from './components.routing';
import { FormService } from 'app/services/form.service';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DataTablesModule } from 'angular-datatables';
import { DialogComponent } from './dialog/dialog.component';
import { TransformPipe } from 'app/institutions/institutions/institutions.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NotFoundComponent } from './not-found/not-found.component';
import { ReportsFormComponent } from './reports-form/reports-form.component';
import { FiltersComponent } from './filters/filters/filters.component';
import { FiltersService } from './filters/filters/filter-service';
import { ButtonComponent } from './grid/button/button.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import {Ng2TelInputModule} from 'ng2-tel-input';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import {NgSelectModule} from '@ng-select/ng-select';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(ComponentsRoutes),
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        DataTablesModule,
        AngularMultiSelectModule,
        Ng2TelInputModule,
        InternationalPhoneNumberModule,
        NgCircleProgressModule.forRoot({
            // set defaults here
            radius: 100,
            outerStrokeWidth: 16,
            innerStrokeWidth: 8,
            outerStrokeColor: '#78C000',
            innerStrokeColor: '#C7E596',
            animationDuration: 300,
        }),
        NgSelectModule,
    ],
    exports: [GridComponent, FormComponentComponent, DialogComponent, TransformPipe, ReportsFormComponent,FiltersComponent,ButtonComponent],
    declarations: [GridComponent, FormComponentComponent, DialogComponent, TransformPipe, NotFoundComponent, ReportsFormComponent, FiltersComponent,ButtonComponent],
    providers: [FormService,FiltersService]
})

export class ComponentsModule { }
