import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpEventType, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../constants';
import { Observable, of, throwError } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { catchError, delay, retryWhen, take } from 'rxjs/operators';
import { InstitutionsModel } from 'app/institutions/institutions-model';
import { ICD10 } from 'app/diagnosis/icd10/Icd10';
import { UsersModel } from 'app/users/users-model';
import { ChildrensModel, DPGJCModel } from 'app/reports/reports-model';


@Injectable({
  providedIn: 'root'
})
export class GetDataService {

  constructor(private http: HttpClient) { }

  url = environment.apiUrl;

  getData(urlPath: string, respType?): Observable<any> {
    // const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.get(`${this.url}` + urlPath, { responseType: respType ? respType : 'json' });
  }

  updateData(urlPath: string, data: any, appendId = true) {
    const url = `${this.url}`;
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = JSON.stringify(data);
    if (data.id) {
      if(appendId) {
        return this.http.post(url + urlPath + '/' + data.id, body, {
          headers
        })
      } else {
        return this.http.post(url + urlPath + '/', body, {
          headers
        })
      }
      return this.http.post(url + urlPath + '/' + data.id, body, {
        headers
      })
    }
    return this.http.post(url + urlPath + '/', body, {
      headers
    })
  }
  updateStatusData(urlPath: string) {
    const url = `${this.url}`;
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.post(url + urlPath, {
      headers
    })
  }
  addData(urlPath: string, data?: any) {
    const url = `${this.url}`;
    const headers = new HttpHeaders().set('content-type', 'application/json');
    var body = JSON.stringify(data);
    if (body) {
      return this.http.post(url + urlPath, body, {
        headers
      })
    } else {
      return this.http.post(url + urlPath, {
        headers
      })
    }
  }

  deleteData(urlPath: string) {
    const url = `${this.url}`;
    const headers = new HttpHeaders().set('content-type', 'application/json');
    return this.http.delete(url + urlPath, {
      headers
    })
  }

  getDataById(urlPath: string): Observable<any> {
    return this.http.get(`${this.url}` + urlPath).pipe(
      catchError(error => {
        if (error.status === 404) {
          console.error('404 error - resource not found', error);
          return of(null);
        }
        throw error;
      })
    );;
  }

  getToken(accessToken: string): Observable<any> {
    return this.http.get(`${this.url}Account/login2?access_token=` + accessToken);
  }

  public upload(param, data, url) {
    let uploadURL = param.id ? `${this.url}${url}?id=${param.id}&Name=${param.name}&Description=${param.description}&commissionHeadId=${param.commissionHealthId[0].id}&institutionId=${param.institutionId[0].id}` : `${this.url}${url}?Name=${param.name}&Description=${param.description}&commissionHeadId=${param.commissionHealthId[0].id}&institutionId=${param.institutionId[0].id}`;
    param.physicians.forEach(element => {
      uploadURL += `&physicians=${element.id}`

    });
    if(param.subs){
      param.subs.forEach(element => {
        uploadURL += `&subs=${element.id}`

      });
    }
    if (!param.id) {
      return this.http.post<any>(uploadURL, data, {
        // reportProgress: true,
        // observe: 'events',
      }).pipe(map((event: any) => {
        return event
      }, catchError((errorResponse: HttpErrorResponse) => {
        return throwError(errorResponse);
      }))
      );
    } else {
      return this.http.post<any>(uploadURL, data, {
      }).pipe(map((event) => {
        return event;
      }), catchError((errorResponse: HttpErrorResponse) => {
        return throwError(errorResponse);
      })
      );
    }
  }

  updateDataWithoutParams(path: string){
    const url = `${this.url}`;
    const headers = new HttpHeaders().set('content-type', 'application/json');
      return this.http.post(url + path, {
        headers
      })
  }

  downloadFile(urlPath: string) {
    return this.http.get(`${this.url}` + urlPath,
     {
       observe: 'response', responseType: 'blob'
     })
 }

  getAllInsitutions(urlPath: string): Observable<InstitutionsModel[]> {
    return this.http.get<InstitutionsModel[]>(`${this.url}` + urlPath);
  }

  getICD10Diagnoses(urlPath: string): Observable<ICD10[]> {
    return this.http.get<ICD10[]>(`${this.url}` + urlPath);
  }

  getOtherUsersByRole(urlPath: string): Observable<UsersModel[]> {
    return this.http.get<UsersModel[]>(`${this.url}` + urlPath);
  }

  getUserById(urlPath: string): Observable<UsersModel> {
    return this.http.get(`${this.url}` + urlPath).pipe(
      catchError(error => {
        if (error.status === 404) {
          console.error('404 error - resource not found', error);
          return of(null);
        }
        throw error;
      })
    );
  }

  getDpgjcData(nid: string): Observable<DPGJCModel> {
    return this.http.get<DPGJCModel>(`${this.url}` + 'Interop/getPersonByNid?Nid='+nid).pipe(
      retryWhen((errors) =>
        errors.pipe(
          delay(1000), // Wait for 1 seconds before retrying
          // Limit retries to 5 attempts
          take(5),
          catchError((err) => {
            console.error('DPGJC retries exhausted:', err);
            return throwError(() => new Error('Failed after 5 retries and delays'));
          })
        )
      ),
      catchError((error) => {
        console.error('Error occurred:', error);
        return throwError(() => new Error('Failed request'));
      })
    );
  }

  getFamilyMemberUnderOne(nid: string): Observable<DPGJCModel[]> {
    return this.http.get<DPGJCModel[]>(`${this.url}` + 'Interop/getFamilyMembersUnderOne?memberNid='+nid).pipe(
      retryWhen((errors) =>
        errors.pipe(
          delay(1000), // Wait for 1 seconds before retrying
          // Limit retries to 5 attempts
          take(5),
          catchError((err) => {
            console.error('DPGJC retries exhausted:', err);
            return throwError(() => new Error('Failed after 5 retries and delays'));
          })
        )
      ),
      catchError((error) => {
        console.error('Error occurred:', error);
        return throwError(() => new Error('Failed request'));
      })
    );
  }

  getFamilyMemberUnderSixteen(nid: string): Observable<ChildrensModel[]> {
    return this.http.get<ChildrensModel[]>(`${this.url}` + 'Interop/getFamilyMembersUnderSixteen?memberNid='+nid).pipe(
      retryWhen((errors) =>
        errors.pipe(
          delay(1000), // Wait for 1 seconds before retrying
          // Limit retries to 5 attempts
          take(5),
          catchError((err) => {
            console.error('DPGJC retries exhausted:', err);
            return throwError(() => new Error('Failed after 5 retries and delays'));
          })
        )
      ),
      catchError((error) => {
        console.error('Error occurred:', error);
        return throwError(() => new Error('Failed request'));
      })
    );
  }

}

