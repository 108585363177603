import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import * as _ from "lodash";

@Injectable()
export class FiltersService {
    private filteredData = new Subject<any[]>();

    public applyFilters(source,filteredSource,activeFilters) {
        source = _.filter(filteredSource, _.conforms(activeFilters));
        this.filteredData.next(source);
    }

    public clearFilters(source,filteredSource,activeFilters) {
        source = _.filter(filteredSource, _.conforms(activeFilters));
        this.filteredData.next();
    }

    getFilteredData(): Observable<any[]> {
        return this.filteredData.asObservable();
    }

    changeFilters(source,index,newFilter){
        source[index]=newFilter;
    }
   
    constructor() { }

}