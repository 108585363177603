import { Injectable } from '@angular/core';
declare var $: any;
export enum NotificationType {
    Success = 'success',
    Error = 'danger',
    Warning = 'warning',
    Info = 'info'
}

@Injectable({
    providedIn: 'root'
})
export class NotificationService {

    constructor() { }

    showNotification(from: string, align: string, message: string[] | string, type: NotificationType) {
        Array.isArray(message) ? Array.from(message).forEach(message => {
            $.notify({
                icon: "ti-gift",
                message: message
            },
                {
                    type: type,
                    timer: 1000,
                    placement: {
                        from: from,
                        align: align
                    },
                    template: '<div data-notify="container" class="col-11 col-md-4 alert alert-{0} alert-with-icon" role="alert"><button type="button" aria-hidden="true" class="close" data-notify="dismiss"><i class="nc-icon nc-simple-remove"></i></button><span data-notify="icon" class="nc-icon nc-bell-55"></span> <span data-notify="title">{1}</span> <span data-notify="message">{2}</span><div class="progress" data-notify="progressbar"><div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div></div><a href="{3}" target="{4}" data-notify="url"></a></div>'
                });
        }) : $.notify({
            icon: "ti-gift",
            message: message
        },
            {
                type: type,
                timer: 1000,
                placement: {
                    from: from,
                    align: align
                },
                template: '<div data-notify="container" class="col-11 col-md-4 alert alert-{0} alert-with-icon" role="alert"><button type="button" aria-hidden="true" class="close" data-notify="dismiss"><i class="nc-icon nc-simple-remove"></i></button><span data-notify="icon" class="nc-icon nc-bell-55"></span> <span data-notify="title">{1}</span> <span data-notify="message">{2}</span><div class="progress" data-notify="progressbar"><div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div></div><a href="{3}" target="{4}" data-notify="url"></a></div>'
            });
    }

 
}


