import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IOptions } from '../form-component/form-component.component';

declare var $: any;

export enum ModalType {
  CancelConfirm,
  PIN,
  Title,
  Reason,
  Display,
  MedicsSigners,
  KML
}

export enum ModalAction {
  Cancel = 0,
  Confirm = 1,
  Yes = 2,
  No = 3
}
export interface IDialog {
  value: any;
  key: string;
  label: string;
  required: boolean;
  order?: number;
  modalType: ModalType;
  type: string;
}

export class ModalDefinition {
  title: string;
  body: any;
  errorLabel: any;
  modalType: ModalType;
  handler?(action: ModalAction, dataToReturn: any);
}

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss']
})
export class DialogComponent implements OnInit {
  public arrayData: any[]
  /*
  * modal model
  */
  @Input() model: ModalDefinition;

  /*
  * modal type
  */
  protected modalType = ModalType;

  /*
  * modal action
  */
  public modalAction = ModalAction;
  dropdownSettings = {};
  optionsDropdown: IOptions[];
  selectedItems: any[];
  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    if (this.model.modalType === ModalType.Display || this.model.modalType === ModalType.MedicsSigners) {
      this.arrayData = [...this.model.body] as Array<any>;
    }
    if (this.model.modalType === ModalType.KML) {
      this.selectedItems = []
      this.dropdownSettings = {
        singleSelection: true,
        text: "Zgjidh kml",
        selectAllText: 'Select All',
        unSelectAllText: 'UnSelect All',
        classes: "cs-dropdown",
        labelKey: "value",
        primaryKey: "key",
      };
      this.optionsDropdown = [...this.model.body] as Array<any>;
    }

  }



  reset(event) {
    this.model.errorLabel = null
  }

  ngAfterViewInit() {
    //  Init Bootstrap Select Picker
    if ($(".selectpicker").length !== 0) {
      $(".selectpicker").selectpicker({
        iconBase: "nc-icon",
        tickIcon: "nc-check-2"
      });
    }
  }






}
