<div class="logo">
    <a href="/" class="simple-text logo-normal">Raporte Mjekësore</a>
</div>


<div class="sidebar-wrapper">
    <ul class="nav">
        <ng-container *ngFor="let menuitem of menuItems">
            <li routerLinkActive="active" *ngIf="inArray(menuitem.permission)">
                <!--If is a single link-->
                <a [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link'">
                    <i class="{{menuitem.icontype}}"></i>
                    <p>{{menuitem.title}}</p>
                </a>
                <!--If it have a submenu-->
                <a data-toggle="collapse" href="#{{menuitem.collapse}}" *ngIf="menuitem.type === 'sub'">
                    <i class="{{menuitem.icontype}}"></i>
                    <p>{{menuitem.title}}<b class="caret"></b></p>
                </a>

                <!--Display the submenu items-->
                <div id="{{menuitem.collapse}}" class="collapse" *ngIf="menuitem.type === 'sub'">
                    <ul class="nav">
                        <ng-container *ngFor="let childitem of menuitem.children">
                            <li routerLinkActive="active" *ngIf="inArray(childitem.permission) && childitem.show!==false">
                                <a style="padding: 5px 8px 5px 54px;" [routerLink]="[menuitem.path + childitem.path]">
                                    <span class="sidebar-normal">{{childitem.title}}</span>
                                </a>
                            </li>
                        </ng-container>
                    </ul>
                </div>
            </li>
        </ng-container>
    </ul>

</div>