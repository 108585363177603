import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Roles } from 'app/services/enum.service';

export interface ButtonOptions {
  key: string;
  show?: boolean;
  icon?: string;
  text?: string;
  cssClasses?: string[];
  tooltip?: string;
  changeMethod?(): any;
  roles?: Roles[]
}

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.css']
})
export class ButtonComponent implements OnInit {

  @Input() options: ButtonOptions;

  @Output() clicked = new EventEmitter();

  constructor() { }

  ngOnInit(): void {

  }

  onClick($event) {
    this.clicked.emit($event);
  }

}
