
import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";



export function emptyAndZeroLength(chips: any[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const lessThanZero = (control.value == null || control.value === '') && chips.length <= 0
        return lessThanZero ? { lessThanZero: { value: true } } : null;
    };
}

export function integerNumberValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
        const isNotOk =
            !Number.isInteger(control.value) || Number(control.value) < 0;
        return isNotOk ? { nonIntegerPositive: { value: control.value } } : null;
    };
}
    export function cofirmpassword(oldPassword): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } => {
            const isNotOk =
                oldPassword?.toString() !== control.value?.toString()
            return isNotOk ? { nonIntegerPositive: { value: control.value } } : null;
            
        };
    
    

    }
