import { IReportForm } from 'app/components/reports-form/reports-form.component';
import { ReportTypes } from './enum.service';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ControlType } from 'app/components/form-component/control-type-enum';


import { IForm, IOptions } from 'app/components/form-component/form-component.component';

@Injectable()
export class FormService {
    protected reportTypes = ReportTypes;
    constructor() { }

    toFormGroup(form: IForm[]) {
        let fb: FormGroup = new FormGroup({});
        const group: any = {};

        form.forEach(form => {
            if (form.controlType === ControlType.select || form.controlType === ControlType.multipleSelect) {
                if (form.value !== null && form.value !== undefined) {
                    if (form?.nestedKeyForDropdowns) {
                        form.value = this.getMultipleSelectObjectsWithNestedKey(form.value, form.options, form.nestedKeyForDropdowns);
                    }
                    else {
                        form.value = this.getMultipleSelectObjects(form.value, form.options);
                    }
                }
            }
            if (form.controlType !== ControlType.headline && form.controlType !== ControlType.subHeadline && form.controlType !== ControlType.break) {
                group[form.key] = form.required ? new FormControl({ value: form.value, disabled: form.readonly }, !form.validators ? Validators.required : [Validators.required, form.validators]) : new FormControl({ value: form.value, disabled: form.readonly });
            }
        });
        fb = new FormGroup(group).getRawValue();
        return new FormGroup(group)
    }

    reportFormGroup(form: IReportForm[], reportType: ReportTypes) {
        let fb: FormGroup = new FormGroup({});
        const group: any = {};
        form.forEach(form => {
            let reportIncluded: boolean = this.isReportIncluded(reportType, form.reportType);
            if (reportIncluded) {
                if (form.controlType === ControlType.select || form.controlType === ControlType.multipleSelect) {
                    if (form.value) {
                        form.value = this.getMultipleSelectObjects(form.value, form.options);
                    }
                }
                group[form.key] = form.required ? new FormControl({ value: form.value, disabled: form.disabled }, Validators.required) : new FormControl({ value: form.value, disabled: form.disabled });
            }
        });
        fb = new FormGroup(group).getRawValue();
        return new FormGroup(group)
    }

    /*
    *   check if report type exists in forms array types
    */
    public isReportIncluded(reportType: ReportTypes, reportTypes: ReportTypes[]): boolean {
        if (reportTypes.includes(reportType)) {
            return true;
        }
        return false;
    }


    getMultipleSelectObjects(ids: string[], values: IOptions[]) {
        const options: IOptions[] = [];
        if (ids !== null && ids !== undefined) {
            if (ids instanceof Array) {
                ids.forEach(id => {
                    const val = values.find(x => x?.key === id);
                    options.push(val);
                });
            } else {
                const val = values.find(x => x?.key === ids);
                options.push(val);
            }
            return options;
        }
    }

    getMultipleSelectObjectsWithNestedKey(ids: any[], values: IOptions[], nestedKey: string) {
        const options: IOptions[] = [];
        
        if (ids) {
            if (ids instanceof Array) {
                ids.forEach(id => {
                    const val = values.find(x => x?.key === id[nestedKey]);
                    if (val) {
                        options.push(val);
                    }
                });
            } else {
                const val = values.find(x => x?.key === ids[nestedKey]);
                if (val) {
                    options.push(val);
                }
            }
            return options;
        }
    }

}
