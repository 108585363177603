import { Roles } from './../services/enum.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GetDataService } from 'app/services/getData.service';
import { NotificationService, NotificationType } from 'app/services/notifications.service';
import { cofirmpassword } from 'app/shared/customValidators';
import { UsersModel } from 'app/users/users-model';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import {AuthenticationService} from '../services/authenticationService';


@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit, OnDestroy {
  private confirmPass$: Subscription;
  public formSource: FormGroup;
  public formGroup: FormGroup;
  public user;
  isReady;
  public showPassForm: boolean = true;
  public showOldPassword: boolean = false;
  public showNewPassword: boolean = false;
  public showRepeatPassword: boolean = false;
  public isPhoneEnable: boolean = false;

  constructor(
    private dataService: GetDataService,
    private router: Router,
    private route: ActivatedRoute,
    private _notificationService: NotificationService,
    private spiner: NgxSpinnerService,
    private _auth: AuthenticationService
  ) {
    this.formGroup = new FormGroup({
      phoneNumber: new FormControl({value: '', disabled: true}, [Validators.required, Validators.pattern(/^\d{3}$|^\d{10}$/)]),
    });
  }

  ngOnDestroy(): void {
    this.confirmPass$.unsubscribe()
  }

  ngOnInit() {
    this.spiner.show()
    this.getUser();
    this.formSource = new FormGroup({
      newPassword: new FormControl(null, Validators.required),
      confirmPassword: new FormControl(null, Validators.compose([Validators.required])),
    });

    this.confirmPass$ = this.formSource.get('newPassword').valueChanges.subscribe(
      (data) => {
        this.formSource.get('confirmPassword').setValidators([Validators.required, cofirmpassword(data)])
      }
    )
  }

  togglePassword(field: number){
    if(field === 1){
      this.showOldPassword = !this.showOldPassword;
    }else if (field === 2){
      this.showNewPassword = !this.showNewPassword;
    } else if( field === 3){
      this.showRepeatPassword = !this.showRepeatPassword;
    }
  }

  async getUser() {
    const token = JSON.parse(localStorage.getItem('currentUser'));
    let user = (JSON.parse(atob(token.access_token.split('.')[1])));
    this.user = await this.getUserById(user.sub);
    if (this.user.roles.includes(Roles.MjekFamilje.toString())){
      this.showPassForm = false;
    }
    this.formGroup.controls['phoneNumber'].setValue(this.user.phoneNumber);
  }

  changePhoneNumber() {
    if (!this.isPhoneEnable) {
      this.formGroup.controls['phoneNumber'].enable();
      this.isPhoneEnable = true;
    } else if (this.formGroup.valid) {
      this.dataService.addData('Account/users/me/changePhoneNr', { ...this.formGroup.value }).subscribe((data) => {
            this._notificationService.showNotification('top','right','Numri u ndryshua me sukses',NotificationType.Success);
            this.isPhoneEnable = false;
            this.formGroup.controls['phoneNumber'].disable();
          },
          error => {
            this._notificationService.showNotification('top','right', error.error, NotificationType.Error);
          })
    }

  }

  /*
 * get current user
 */
  protected getUserById(id: string): Promise<UsersModel> {
    return new Promise(
      (resolve, reject) => {
        this.dataService.getDataById('Account/users/' + id).subscribe(
          (data: UsersModel) => {
            this.spiner.hide()
            this.isReady = true;
            resolve(data);
          },
          (error) => {
            this.spiner.hide()
            this.isReady = false;
            reject(error);
          }
        )
      }
    );
  }

  onSubmitForm() {
    if (this.formSource.valid) {
      this.dataService.updateData('Account/users/me/changepassword', { ...this.formSource.value }).subscribe((data) => {
        this._notificationService.showNotification('top','right','Passwordi u ndryshua me sukses',NotificationType.Success);
        this.router.navigate(['/dashboard'])
        setTimeout(() => {
          this._auth.logout();
        }, 1000);
       },
       error => {
            this._notificationService.showNotification('top','right', error.error, NotificationType.Error);
       })
    }
  }

}
