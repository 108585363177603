import { InstitutionsModel } from './../institutions-model';
import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DialogComponent, ModalAction, ModalDefinition, ModalType } from 'app/components/dialog/dialog.component';
import { IColumns, IdataTablesParameters, IGridMainButtons } from 'app/components/grid/grid.component';
import { RolesModel } from 'app/roles/roles-model';
import { GetDataService } from 'app/services/getData.service';
import { NotificationService, NotificationType } from 'app/services/notifications.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { ButtonOptions } from 'app/components/grid/button/button.component';
import { Roles } from 'app/services/enum.service';
import { IForm } from 'app/components/form-component/form-component.component';
import { ControlType } from 'app/components/form-component/control-type-enum';
import { UserService } from 'app/services/user-service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

@Pipe({ name: 'transformPipe' })
export class TransformPipe extends DatePipe implements PipeTransform {
  transform(row: any, fieldName: any, pipeType: string, pipeColumn, fieldsToAcess?): any {
    switch (pipeType) {
      case "transformPipe":
        if (row[fieldName]) {
          return 'Po';
        }
        return 'Jo';
      case "date":
        return super.transform(row[fieldName], "dd-MM-yyyy")

      case "html":
        if (row[fieldName] === 0) {
          pipeColumn.innerHTML = '<div style="text-align:center;"><i class="fa fa-file-text" data-toggle="tooltip" data-placement="top"  title="I Panënshkruar" bg-primary  style="font-size:26px;color:gray" aria-hidden="true"></i><br>I Panënshkruar</div>'

        }
        else if (row[fieldName] === 1) {
          pipeColumn.innerHTML = '<div style="text-align:center;"><i class="fa fa-file-text" data-toggle="tooltip" data-placement="top"  title="Pjesërisht Nënshkruar"  bg-primary  style="font-size:26px;color:gold" aria-hidden="true"></i><br>Pjesërisht Nënshkruar</div>'
        }
        else if (row[fieldName] === 2) {
          pipeColumn.innerHTML = '<div style="text-align:center;"><i class="fa fa-file-text" data-toggle="tooltip" data-placement="top"  title="Nënshkruar"  bg-primary  style="font-size:26px;color:lightgreen" aria-hidden="true"></i><br>Nënshkruar</div>'
        }
        else if (row[fieldName] === 3) {
          pipeColumn.innerHTML = '<div style="text-align:center;"><i class="fa fa-file-text" data-toggle="tooltip" data-placement="top"  title="Refuzuar"  bg-primary  style="font-size:26px;color:lightcoral" aria-hidden="true"></i><br>Refuzuar</div>'
        }
        else if (row[fieldName] === 4) {
          pipeColumn.innerHTML = '<div style="text-align:center;"><i class="fa fa-file-text" data-toggle="tooltip" data-placement="top"  title="I Pafvlefshëm"  bg-primary  style="font-size:26px;color:#FF892D" aria-hidden="true"></i><br>I Pafvlefshëm</div>'
        }

        break;
      case "array":
        return row[fieldName].map(el => {
          if (!Array.isArray(fieldsToAcess)) {
            return row[fieldName][fieldsToAcess]
          }
          return fieldsToAcess.map(field => { return el?.[field] }).join().replace(',', ' ')
        })

      case "object":
        if (!Array.isArray(fieldsToAcess)) {
          if (fieldsToAcess.toString().includes('.')) {
            fieldsToAcess.split('.').map(word => {
              row[fieldName] = row?.[fieldName]?.[word]
            })
            return row[fieldName]
          }
          return row[fieldName][fieldsToAcess]
        }
        return fieldsToAcess.map(field => { return row[fieldName]?.[field] }).join().replace(',', ' ')
    }

  }

}
@Component({
  selector: 'app-institutions',
  templateUrl: './institutions.component.html',
  styleUrls: ['./institutions.component.scss']
})
export class InstitutionsComponent implements OnInit {
  /*
  * datatables options
  */
  protected dtOptions: DataTables.Settings = {};
  /*
  * Datagrid title
  */
  protected title: string;

  protected extraButtons: ButtonOptions[] = [];
  /*
  * datasource
  */
  protected dataSource: InstitutionsModel[];
  /*
  * columns
  */
  protected columns: IColumns[];

  /*
  * is data ready
  */
  public isReady: boolean = false;

  protected dateFilters: IForm[];


  /*
  * modal ref
  */
  protected modalRef: any;
  /*
  * route for adding institutions
  */
  public route: string;
  isAdmin: boolean;

  public gridMainButtons: IGridMainButtons[];

  /*
  * filtered string
  */
  protected filterString: string = '';

  /*
  * columns
  */
  protected tableFilters: IForm[];

  constructor(
    private _service: GetDataService,
    public modalService: NgbModal,
    private router: Router,
    private userService:UserService,
    private _notificationService: NotificationService,
    private spinner: NgxSpinnerService,
  ) { }


  ngOnInit() {
    this.route = "institutions/add"
    this.isAdmin=this.userService.checkForRole([Roles.Administrator])
    this.spinner.show();
    this.title = "Institucionet";
    this.columns = [
      { name: 'name', caption: "Emri" },
      { name: 'code', caption: "Kodi" },
      { name: 'institutionTypeName', caption: "Lloji" },
      { name: 'isPrivateInstitution', caption: "Institucion Privat", pipeName: 'transformPipe' },
      { name: 'administrativeUnitName', caption: "Njesia Administrative" },
      { name: 'cityHallName', caption: "Bashkia" },
      { name: 'regionName', caption: "Qarku" },
    ];

    this.gridMainButtons = [
      {
        title: 'Shkarko',
        icon: 'fa fa-download',
        class: 'btn btn-success',
        roles: [Roles.Administrator],
        action: (data: any) => {
          this.downloadInstitutions();
        }
      }
    ];

    this.tableFilters = [
      {
        value:  null,
        key: 'name',
        label: 'Emri',
        required: false,
        order: 1,
        controlType: ControlType.input,
        type: 'text',
      },
      {
        value:  null,
        key: 'institutionCode',
        label: 'Kodi',
        required: false,
        order: 2,
        controlType: ControlType.input,
        type: 'text',
      }
    ]

    this.dateFilters = [
      {
        value: null,
        key: 'dateFrom',
        label: 'Date From',
        required: false,
        order: 1,
        controlType: ControlType.datepicker,
        type: 'datepicker',
      },
      {
        value: null,
        key: 'dateTo',
        label: 'Date To',
        required: false,
        order: 2,
        controlType: ControlType.datepicker,
        type: 'datepicker',
      },

    ]

    this.dtOptions = {
      pagingType: 'full_numbers',
      serverSide: true,
      processing: true,
      pageLength: 10,
      retrieve: true,



      ajax: async (param: IdataTablesParameters, callback) => {
        const start = param.start / param.length + 1;
        await this._service.getData(`Institution/getAll?PageNumber=${start}&PageSize=${param.length}${this.filterString}`).subscribe(
          (data: InstitutionsModel[]) => {
            this.dataSource = data['items'];
            this.dataSource.forEach(item => {
              item['administrativeUnitName'] = item['administrativeUnit']?.name
              item['cityHallName'] = item['administrativeUnit']?.cityHallName
              item['regionName'] = item['administrativeUnit']?.regionName
            })
            this.spinner.hide();
            callback({
              recordsTotal: data['totalCount'],
              recordsFiltered: data['totalCount'],

              data: []
            });
          }
        )
      }
    };
    this.isReady = true;
  }

  protected filterData(filters: any[]) {
    let queryString: string = '';
    if (filters) {
      for (let key in filters) {
        if (filters[key] && filters[key] != '') {
          queryString += '&' + key + '=' + filters[key];
        } else if (filters[key] === 0) {
          queryString += '&' + key + '=' + filters[key];
        }
      }
    }
    this.filterString = queryString;

    this.dtOptions = {
      pagingType: 'full_numbers',
      serverSide: true,
      processing: true,
      pageLength: 10,
      retrieve: true,
      ajax: async (param: IdataTablesParameters, callback) => {
        const start = param.start / param.length + 1;
        await this._service.getData('Institution/getAll?PageNumber=' + start + '&PageSize=' + param.length + this.filterString).subscribe(
            (data: InstitutionsModel[]) => {
              this.dataSource = data['items'];
              this.dataSource.forEach(item => {
                item['administrativeUnitName'] = item['administrativeUnit']?.name
                item['cityHallName'] = item['administrativeUnit']?.cityHallName
                item['regionName'] = item['administrativeUnit']?.regionName
              })
              this.spinner.hide();
              callback({
                recordsTotal: data['totalCount'],
                recordsFiltered: data['totalCount'],

                data: []
              });
            }
        )
      }
    };
  }

  /**
   * Download institutions list
   */
  protected downloadInstitutions() {
    this.spinner.show();
    this._service.downloadFile('Institution/ExportInstitutions/').subscribe(
      (data) => {
        if (data.status !== 200) {
          this._notificationService.showNotification('top', 'right', 'Dështoi shkarkimi!', NotificationType.Error);
        } else {
          let file = data.headers.get('content-disposition')
  
          let fileName = data.headers.get('content-disposition')?.split(';')[1].trim().split('=')[1];
          let blob: Blob = data.body as Blob;
  
          let a = document.createElement('a');
          a.download = fileName;
          a.download = "Lista e institucioneve";
          a.href = window.URL.createObjectURL(blob);
          a.click();
        }
        this.spinner.hide();
      },
      (error) => {
        this._notificationService.showNotification('top', 'right', 'Dështoi shkarkimi!', NotificationType.Error);
        this.spinner.hide();
      }
      )
  }

  /**
   * Edit datasource row
   */
  protected edit(row: RolesModel) {
    this.router.navigate(['institutions/edit/' + row.id]);
  }



  getFormattedDate(date) {
    const dateObj = new Date(date);
    let mm: any = dateObj.getMonth() + 1;
    let dd: any = dateObj.getDate();
    const yyyy = dateObj.getFullYear();
    if (dd < 10) {
        dd = '0' + dd;
    }
    if (mm < 10) {
        mm = '0' + mm;
    }
    return yyyy + '-' + mm + '-' + dd;
}
  /**
   * Delete datasource row
   * @param row RolesModel
   */
  protected delete(row: InstitutionsModel) {
    const modal = new ModalDefinition;
    modal.title = 'Fshij?';
    modal.body = 'Jeni i sigurte per fshirjen e ' + row.name + '?';
    modal.modalType = ModalType.CancelConfirm;
    modal.handler = (action: ModalAction) => {
      if (action == ModalAction.Confirm) {
        this.spinner.show();
        this._service.deleteData('Institution/delete/' + row.id).subscribe(
          (data) => {
            const index: number = this.dataSource.findIndex(d => d.id === row.id);
            this.dataSource.splice(index, 1);
            this.modalRef.close();
            this._notificationService.showNotification('top', 'right', 'Institucioni u fshi me sukses!', NotificationType.Success);
            this.spinner.hide();
          },
          (error) => {
            this.modalRef.close();
            this._notificationService.showNotification('top', 'right', 'Dështoi fshirja e institucionit!', NotificationType.Error);
            this.spinner.hide();
          }
        )
      } else if (action == ModalAction.Cancel) {
        this.modalRef.close();
      }
    }
    this.modalRef = this.modalService.open(DialogComponent);
    this.modalRef.componentInstance.model = modal;

  }
  handleClick(event) {
    switch (event.action) {
      case 'edit': {
        this.edit(event.event)
        break
      }
      case 'delete': {
        this.delete(event.event)
        break
      }
    }
  }




}
