import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams, HttpHandler, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { environment } from '../constants';
import { ActivatedRoute, Router } from '@angular/router';
import { GetDataService } from './getData.service';
import { UsersModel } from 'app/users/users-model';
import jwt_decode from 'jwt-decode';
export interface User {
    access_token: number;
    expires_in: string;
    token_type: string;
    refresh_token: string;
    scope: string;
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    public currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;
    constructor(private http: HttpClient, private router: Router, private actRoute: ActivatedRoute, private dataService: GetDataService) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }
    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    private getParameterByName(name) {
        const url = window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
            results = regex.exec(url);
        if (!results) return null;
        if (!results[2]) return '';
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
    }

    checklogin() {
        if (!window.location.pathname.startsWith('/api/Account/login')) {
            if (!this.currentUserValue) {
                this.router.navigate(['/auth/login']);
            } else {
                const token = JSON.parse(localStorage.getItem('currentUser'));
                if (token.access_token) {
                    const expiry = (JSON.parse(atob(token.access_token.split('.')[1]))).exp;
                    if ((Math.floor((new Date).getTime() / 1000)) >= expiry) {
                        this.router.navigate(['/auth/login']);
                    }else if((JSON.parse(atob(token.access_token.split('.')[1]))).isFirstLogin === 'True'){
                        this.router.navigate(['/auth/changePassword']);
                    }
                } else {
                    this.router.navigate(['/auth/login']);
                }
            }
        }

       


    }

    isAuthenticated(): boolean {
        const token = JSON.parse(localStorage.getItem('currentUser'));
        const expiry = (JSON.parse(atob(token.access_token.split('.')[1]))).exp;
        if ((Math.floor((new Date).getTime() / 1000)) >= expiry) {
            return false;
        }
        return true;
    }

    login(username: string, password: string) {
        const body = new HttpParams()
            .set('grant_type', 'password')
            .set('username', username)
            .set('password', password)
            .set('client_id', 'eraport_spa')

        let headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': "Basic Zmlza2FsaXppbWlfc3BhOg==",
        });
        let options = { headers: headers };
        return this.http.post<any>(environment.baseUrl + 'connect/token', body, options)
            .pipe(map(user => {
                if (user && user.access_token) {
                    localStorage.setItem('currentUser', JSON.stringify(user));
                    this.currentUserSubject.next(user);
                }
                return user;
            }));

    }

    changePassword(currentPassword: string, newPassword: string, confirmPassword: string) {
        const body = new HttpParams()
            .set('currentPassword', currentPassword)
            .set('newPassword', newPassword)
            .set('confirmPassword', confirmPassword)

            const bodyy = {
                currentPassword: currentPassword,
                newPassword: newPassword,
                confirmPassword: confirmPassword,
            }

        let headers = new HttpHeaders({
            'Content-Type': 'application/json'
        });
        let options = { headers: headers };
        return this.http.post<any>(environment.baseUrl + 'api/Account/users/me/changepassword', JSON.stringify(bodyy), options);

    }

    logout() {
        const tokenString = localStorage.getItem('currentUser');
        if (tokenString) {
            const token = JSON.parse(tokenString);
            if (token) {
                const decodedToken: any = jwt_decode(token.access_token);
                if (decodedToken.isPhysician === 'True') {
                    window.location.href = "https://e-albania.gov.al/fpsts/default.aspx?wtrealm=urn:portaliimjekut&wa=wsignout1.0&wreply=https://eraporte.gov.al";
                } else {
                    window.location.href = "https://e-albania.gov.al/fpsts/default.aspx?wtrealm=urn:eraporte.gov.al&wa=wsignout1.0&wreply=https://eraporte.gov.al";
                }
            }

        }
        localStorage.removeItem('currentUser');
        localStorage.removeItem('role');
        this.currentUserSubject.next(null);
        this.router.navigate(['/auth/login']);
        localStorage.clear();
    }

    refreshToken() {
        const refresh_token = JSON.parse(localStorage.getItem('currentUser'));
        const body = new HttpParams()
            .set('grant_type', 'refresh_token')
            .set('refresh_token', refresh_token.refresh_token)
            .set('client_id', 'eraport_spa');

        let headers = new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
            'Authorization': 'Basic Zmlza2FsaXppbWlfc3BhOg==',
        });

        let options = { headers: headers };
        return this.http.post<any>(environment.baseUrl + 'connect/token', body, options);
    }



}
