import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { environment } from '../constants';
import { AuthenticationService } from '../services/authenticationService';
import jwt_decode from 'jwt-decode';
import {catchError, finalize, first, tap} from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authenticationService: AuthenticationService) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to api url
        
        const currentUser = this.authenticationService.currentUserValue;
        const isLoggedIn = currentUser && currentUser.access_token;
        const tokenData = localStorage.getItem('currentUser');
        const isApiUrl = request.url.startsWith(environment.baseUrl);
        if (isLoggedIn && isApiUrl && request.url != environment.baseUrl+'connect/token' && tokenData) {
            const tokenObj = JSON.parse(tokenData);
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${tokenObj.access_token}`
                }
            });
        }
        return next.handle(request).pipe(
            tap((evt) => {
                    if (request.url === environment.baseUrl + 'connect/token' && evt instanceof HttpResponse) {
                        setTimeout(() => {
                            this.refreshToken().subscribe((x) => {
                                localStorage.removeItem('currentUser');
                                localStorage.setItem('currentUser', JSON.stringify(x));
                                this.authenticationService.currentUserSubject.next(x);
                            });
                        }, (evt.body['expires_in'] * 1000));
                    }
            }),
            catchError((err) => {
                if (err instanceof HttpErrorResponse) {
                    if (err.status == 401) {
                        this.refreshOrLogout();
                    }
                }
                return throwError(err);
            })
        );
    }

    refreshOrLogout() {
        if (localStorage.getItem('refresh_token')) {
            this.refreshToken().subscribe((x) => {
                location.reload();
            });
        } else {
            this.authenticationService.logout();
        }
    }

    refreshToken() {
        return this.authenticationService.refreshToken().pipe(
            first(),
            catchError((err) => {
                if (err instanceof HttpErrorResponse) {
                    this.authenticationService.logout();
                }
                return throwError(err);
            }))
    }

}
