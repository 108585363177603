import { Injectable } from "@angular/core";
import { GetDataService } from "./getData.service";
import jwt_decode from "jwt-decode";
import { Roles } from "./enum.service";

@Injectable({
    providedIn: 'root',
})
export class UserService {
    user: any;
    userId: any;
    userName: string;
    constructor(private dataService: GetDataService) {
        const token = JSON.parse(localStorage.getItem('currentUser'));
        this.user = (JSON.parse(atob(token.access_token.split('.')[1])))
        this.userId = this.user.sub
    }
    getUserName() {
        this.dataService.getDataById(`Account/users/${this.userId}`).subscribe(
            (user) => {
                this.userName = user.name + ' ' + user.surname;
            }
        )
    }

    /**
     * 
     * @returns user data which is called in getUserByIdMethod above and modifies roles key to not save string anymore but enums,since they are in two different keys.
     */
    getUserData() {
        const token = JSON.parse(localStorage.getItem('currentUser'));
        let user: any = jwt_decode(token.access_token);
        return user
    }

    /**
    * 
    * @returns check for for role depending on the argument of roles you pass
    */

    checkForRole(rolesToShow: Roles[]) {
        let roles: any = this.getUserData().role?.length > 1 ? this.getUserData().role : this.getUserData().role[0];
        if (localStorage.getItem('role') === Roles.Drejtor.toString()) {
            roles = [roles, Roles.Drejtor.toString()]
        }
       let isValid= rolesToShow.some(r => roles.includes(r.toString()));
        return rolesToShow.some(r => roles.includes(r.toString()));
    }




}
