<div class="main-content" *ngIf="isReady">
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-atom"></ngx-spinner>
    <div class="card">
        <div class="card-header text-center ">
            <b>Të dhënat e profilit</b>
        </div>

        <hr>
        <div class="card-body d-lg-flex flex-lg-row flex-md-column">
            <div class="form  col-lg-6 col-12  pl-5  order-md-2 order-lg-1 mt-3 mt-lg-0 text-md-center">
                <div class="col-12  d-flex justify-content-between">
                    <div class="title">
                        <label>NID: </label>
                    </div>
                    <div class="ml-3 info">
                        <p>{{user.userName}}</p>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-between mt-4">
                    <div class="title">
                        <label>Emri: </label>
                    </div>
                    <div class=" ml-3 info">
                        <p>{{user.name}}</p>
                    </div>
                </div>
                <div class="col-12 d-flex justify-content-between mt-4">
                    <div class="title">
                        <label>Mbiemri: </label>
                    </div>
                    <div class=" ml-3 info">
                        <p>{{user.surname}}</p>
                    </div>
                </div>
                <form [formGroup]="formGroup" class="col-12 d-flex justify-content-between mt-4 align-items-center">
                    <div class="title  d-flex ">
                        <label>Numër Telefoni:</label>
                    </div>
                    <div class="info ml-3">
                        <div class="col-12 d-flex justify-content-between align-items-center">
                            <div class="form-group bootstrap-select w-100 mr-2">
                                <input class="form-control" formControlName="phoneNumber">
                            </div>
                            <div class="mb-1">
                                <button type="button" style="font-weight: bold; font-size: 12px; text-align: end; background-image: linear-gradient( 135deg, #536976 10%, #292E49 100%);" class="btn fs-1 mr-4 mb-3" (click)="changePhoneNumber()" [disabled]="formGroup.invalid">{{isPhoneEnable ? 'Ruaj' : 'Ndrysho'}}</button>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="col-12 d-flex justify-content-between">
                    <p>Formati i numrit të telefonit duhet të jetë shqiptar, <span style="color: red">06********</span></p>
                </div>
                <div class="col-12 d-flex justify-content-between mt-4">
                    <div class="title">
                        <label>Roli:</label>
                    </div>
                    <div class="info ml-3">
                        <p>{{user.rolesDescription}}</p>
                    </div>
                </div>

                <div class="col-12 d-flex justify-content-between mt-4 align-items-center">
                    <div class="title  d-flex ">
                        <label>Kodi:</label>
                    </div>
                    <div class="info ml-3">
                        <p class="m-0">{{user.physCode}}</p>
                    </div>
                </div>
            </div>
            <div class="mx-auto pass offset-1 col-lg-5 col-12 pr-4 order-lg-2 order-sm-1 mt-5 mt-md-0" *ngIf="showPassForm">
                <div class="container text-center mr-3 pr-3">
                    <i class="fa fa-user-circle" style="font-size: 7rem;color: #425164;" aria-hidden="true"></i>

                    <form class="mt-2" [formGroup]="formSource" (ngSubmit)="onSubmitForm()">
                        <div class="col-12  text-left fs-6 mb-3  ">
                            <label style="font-size: 15px  !important">Fjalëkalimi i ri</label>
                            <div class="form-group bootstrap-select w-100 ">
                                <input class="form-control" formControlName="newPassword" [type]="(showNewPassword)? 'text' : 'password'">
                                <i class="fa fa-eye" style="position: absolute;right:6px;top:6px;font-size: 24px;color: #555; cursor: pointer; z-index: 9999;" (click)="togglePassword(2)"></i>
                            </div>
                        </div>
                        <div class="col-12  text-left fs-6  ">
                            <label style="font-size: 15px  !important">Konfirmo fjalëkalimin</label>
                            <div class="form-group bootstrap-select w-100 ">
                                <input class="form-control" formControlName="confirmPassword" [type]="(showRepeatPassword)? 'text' : 'password'">
                                <i class="fa fa-eye" style="position: absolute;right:6px;top:6px;font-size: 24px;color: #555; cursor: pointer; z-index: 9999;" (click)="togglePassword(3)"></i>
                            </div>
                        </div>
                        <div class="d-flex w-100 mt-3 mt-md-0 ml-md-0 ml-5">
                            <button type="submit" class="d-flex w-100 ml-auto" style="min-width: 150px;font-weight: bold;font-size: 15px; text-align: end;   background-image: linear-gradient( 135deg, #536976 10%, #292E49 100%);" class="btn  px-3 fs-1 mr-4 mb-3">Ndrysho <i class="fa fa-pencil ml-2"></i></button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
