<div class="row">
    <div class="col-md-12 ">
        <div class="row justify-content-end">
    
                <ng-container *ngIf="extraButtons && extraButtons.length > 0">
                <ng-container *ngFor="let button of extraButtons" >
                    
                    <app-button class="w-100" *ngIf="!button.role || checkForRoles(button.role)"   class="w-100  col-md-2 d-flex justify-content-end"
                  
                        [options]="button" (clicked)="handleClick(row,button.key)">
                    </app-button>
                   
                    
                </ng-container>
                </ng-container>
           
            <div class="col-md-2 w-100 d-flex justify-content-end" *ngIf="route">
                <button *ngIf="!hideCreateBtn" (click)="redirect()"
                    style="font-weight: bold;font-size: 15px;    background-image: linear-gradient( 135deg, #536976 10%, #292E49 100%);"
                    class="btn  px-3 fs-1 mr-4 mb-3 w-100">Krijo <i class="fa fa-plus ml-2"></i></button>
            </div>
        </div>
        <div class="card">

            <div class="card-header">
                <h4 class="card-title d-inline-block mr-3">{{title}}</h4>
                <ng-container *ngIf="gridMainButtons">
                    <ng-container *ngFor="let btn of gridMainButtons">
                        <button *ngIf="checkForRoles(btn.roles)" [ngClass]="btn.class" (click)="btn.action(btn)"><i [ngClass]="btn.icon"></i> {{btn.title}}</button>
                    </ng-container>
                </ng-container>
            </div>
            <!-- <app-filters *ngIf="model && hasFilters" [model]="filters" [source]="model"></app-filters> -->
            <app-form-component *ngIf="tableFilters" [model]="tableFilters" [hasFilter]="hasFilter"
                (save)="save($event)">
            </app-form-component>
            <div class="card-body table-main">
                <table id="datatable" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                    class="table table-striped table-bordered" cellspacing="0" width="100%">
                    <thead>
                        <tr>
                            <th>NR</th>
                            <th *ngFor="let column of columns">{{ column.caption }}</th>
                            <th *ngIf="actions">Veprime</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let row of model; let rowIndex = index">
                            <td>{{rowIndex+1}}</td>
                            <ng-container *ngFor="let col of columns">
                                <td
                                    *ngIf="!col.pipeName && (col.type !== columnType.DISPLAY && col.type !==columnType.MedicsSigned && col.type !==columnType.LINK)">
                                    {{row[col?.name]}}</td>
                                <td *ngIf="col.type===columnType.DISPLAY">
                                    <div class="d-flex justify-content-center">
                                        <button class="btn bg-secondary"
                                            *ngIf="row.type === reportType.PaAftesiKml ||
                                            row.type === reportType.AksidenteSemundjeKml ||
                                            row.type === reportType.SpecialistSpital ||
                                            row.type === reportType.Dite14Spital ||
                                            row.type === reportType.AksidenteSemundjeSpital ||
                                            row.type === reportType.Dite14KuruarJashte ||
                                            row.type === reportType.PuneTeLehta"
                                            (click)="display(col.type,row)">Shfaq</button>
                                    </div>
                                </td>
                                <td *ngIf="col.type===columnType.LINK">
                                    <a [href]="row[col?.name]" target="_blank">{{row[col?.name]}}</a>
                                </td>
                                <td *ngIf="col.type===columnType.MedicsSigned">
                                    <div class="d-flex justify-content-center"><button class="btn bg-secondary"
                                            (click)="displayMedics(row)">Shfaq</button></div>
                                </td>

                                <td *ngIf="col.pipeName" #pipeCol>{{row | transformPipe : col.name: col.pipeName:pipeCol
                                    :col.fieldsToAcess:fieldsToAcess}}
                                </td>
                            </ng-container>

                            <td *ngIf="actions" class="actions text-center ">
                                <!-- <button *ngIf="row['actions'] && !row['actions']?.nonShowable" (click)="showRow(row,rowIndex) " class="btn btn btn-icon btn-sm "><i
                                        class="fa fa-eye "></i></button>
                                <button *ngIf="!row['actions'] || !row['actions']?.nonEditable" (click)="editRow(row)" class="btn btn-warning btn-icon btn-sm edit"><i class="fa fa-edit"></i></button>
                                <button *ngIf="!row['actions'] || !row['actions']?.nonDeletable" (click)=" deleteRow(row) " class="btn btn-danger btn-icon btn-sm remove "><i
                                        class="fa fa-times "></i></button>

                                <button *ngIf="row['actions'] && !row['actions']?.nonDownloadable" (click)="downloadRow(row) " style="color: white;background: red !important;opacity: 0.54;" class="btn bg-white fs-1 fw-bold btn-icon btn-sm "><i
                                        class="fa fa-download "></i></button> -->

                                <ng-container *ngIf="gridActions">
                                    <div *ngFor="let button of gridActions">
                                        <app-button
                                            *ngIf="row['controllActions']?.includes(button.key)||!row['controllActions']"
                                            [options]="button" (clicked)="handleClick(row,button.key)">
                                        </app-button>
                                    </div>

                                </ng-container>
                                <!-- 
                                <ng-template #defaultButtons>
                                    <app-button [options]="{key: 'edit', show: true, icon: 'fa fa-edit', cssClasses: ['bg-warning', 'btn-icon', 'btn-sm', 'fs-1', 'fw-bold', 'mr-1']}" (clicked)="handleClick(row,'edit')">
                                    </app-button>
                                    <app-button [options]="{key: 'delete', show: true, icon: 'fa fa-remove', cssClasses: ['bg-danger', 'btn-icon', 'btn-sm', 'fs-1', 'fw-bold', 'mr-1']}" (clicked)="handleClick(row,'delete')">
                                    </app-button>
                                </ng-template> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <!-- end content-->
        </div>
        <!--  end card  -->
    </div>
    <!-- end col-md-12 -->
</div>
<!-- end row -->
