<nav #navbar class="navbar navbar-expand-lg navbar-absolute" [ngClass]="{ 'bg-white': open === true , 'navbar-transparent': open === false}">
    <div class="container-fluid">
        <div class="navbar-wrapper">
            <div class="navbar-minimize">
                <button id="minimizeSidebar" class="btn btn-icon btn-round" (click)="minimizeSidebar()">
          <i class="nc-icon nc-minimal-right text-center visible-on-sidebar-mini"></i>
          <i class="nc-icon nc-minimal-left text-center visible-on-sidebar-regular"></i>
        </button>
            </div>
            <div class="navbar-toggle" (click)="sidebarToggle()">
                <button type="button" class="navbar-toggler">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
            </div>
            <a class="navbar-brand" href="javascript:void(0)">{{getTitle()}}</a>
        </div>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navigation" aria-controls="navigation-index" aria-expanded="false" aria-label="Toggle navigation" (click)="open = !open">
            <span class="navbar-toggler-bar navbar-kebab"></span>
            <span class="navbar-toggler-bar navbar-kebab"></span>
            <span class="navbar-toggler-bar navbar-kebab"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end" id="navigation">
            <div class="reload"> 
                <i class="nc-icon nc-refresh-69" (click)="reloadPage()"></i>
            </div>
            <div class="notification" *ngIf="nrReportsWithoutSign"> 
                <a href="reports/reports">
                    <i class="nc-icon nc-bell-55"></i>
                    <span>{{nrReportsWithoutSign}}</span>
                    <div class="popupMessage">
                        Ju keni {{nrReportsWithoutSign}} raporte te pafirmosur. Klikoni për të shkuar të faqja e raporteve!
                        <div class="arrow-up"></div>
                    </div>
                </a>
            </div>
<!--            <div class="notification m-0 pb-1">-->
<!--                <ul class="navbar-nav mr-4">-->
<!--                    <li class="nav-item dropdown">-->
<!--                        <div class="custom-btn-notification" (click)="goToManuals()">-->
<!--                            <i class="fa fa-book"></i> Manuale-->
<!--                        </div>-->
<!--                    </li>-->
<!--                </ul>-->
<!--            </div>-->
<!--            <div class="notification m-0 pb-1">-->
<!--                <ul class="navbar-nav mr-4">-->
<!--                    <li class="nav-item dropdown">-->
<!--                        <div class="custom-btn-notification" (click)="goToNotifications()">-->
<!--                            <i class="fa fa-envelope"></i> Njoftime-->
<!--                        </div>-->
<!--                    </li>-->
<!--                </ul>-->
<!--            </div>-->
                <div class="notification m-0 pb-1" *ngIf="institutionName">
                    <ul class="navbar-nav" *ngIf="!isButton" style="margin-right: 20px;">
                        <li>
                            <h3 class="username">{{institutionName}} </h3>
                        </li>
                    </ul>
                    <ul class="navbar-nav mr-4" *ngIf="isButton">
                        <li class="nav-item dropdown">
                            <div class="custom-btn-notification" (click)="openInstitutionPopup()">
                                {{institutionName}}
                            </div>
                        </li>
                    </ul>
                </div>


            <ul class="navbar-nav">
                <li>
                    <h3 class="username">{{user?.name}} {{user?.surname}}</h3>
                </li>
                <li class="nav-item btn-rotate dropdown">
                    <a class="nav-link dropdown-toggle" href="pablo" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <i class="nc-icon nc-settings-gear-65"></i>
                        <p>
                            <span class="d-lg-none d-md-block">Actions</span>
                        </p>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                        <a class="dropdown-item" (click)="goToProfile()">Profili im </a>
                        <a class="dropdown-item" (click)="logout()">Logout</a>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</nav>
