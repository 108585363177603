import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ReportTypes, Roles } from 'app/services/enum.service';
import { GridService } from 'app/services/grid-service';
import { NotificationService } from 'app/services/notifications.service';
import { UserService } from 'app/services/user-service';
import { Subject } from 'rxjs';
import { FiltersService } from '../filters/filters/filter-service';
import { IFilter } from '../filters/filters/filters.component';
import { IForm } from '../form-component/form-component.component';
import { ButtonOptions } from './button/button.component';

export interface IGridMainButtons{
    title: string;
    icon: string;
    class: string;
    roles: Roles[];
    action(data): any;
}

export class IColumns {
    name: string;
    caption: string;
    pipeName?: string;
    type?: FieldType;
    fieldsToAcess?: any;
    isWithMultipleSigners?= false
}

export enum FieldType {
    INPUT,
    DISPLAY,
    MedicsSigned,
    LINK
}
export interface IdataTablesParameters {
    draw: number;
    length: number;
    start: number;
    search: { value: any, regex: string }
}
@Component({
    moduleId: module.id,
    selector: 'app-grid',
    templateUrl: './grid.component.html',
    styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnChanges {
    @Input() filters: IFilter[];
    @Input() gridMainButtons: IGridMainButtons[];
    @ViewChild(DataTableDirective, { static: false })
    dtElement: DataTableDirective;

    // dtOptions: DataTables.Settings = {};

    dtTrigger = new Subject();
    filter: IFilter[]
    columnType: any;
    reportType;
    ngOnInit(): void {
        this.gridService.refreshData.subscribe((data) => {
            this.rerender(data)
        })
        this.columnType = FieldType;
        this.filter = this.filters;
        this.reportType = ReportTypes
        let albanianLanguage = {
            processing: "Proceso",
            search: "Kërko:",
            lengthMenu: "Shfaq _MENU_ rreshta",
            zeroRecords: "Asnjë e dhënë",
            paginate: {
                first: "E para",
                previous: "Para",
                next: "Pas",
                last: "E fundit"
            },
            buttons: {
                copy: "Kopjo",
                csv: "CSV",
                excel: "EXCEL",
                pdf: "PDF",
                print: "Printo",
                collection: "Të dhënat",
                colvis: "Shfaq kolonat",
                colvisRestore: "Rikthe shfaqjen",
                copyKeys: "Shtyp _ctr ose _⌘ + _C për të kopjuar të dhënat në Clipboard. Për të anulluar, kliko mesazhin ose shtyp butonin 'Escape'.",
                copySuccess: {
                    _: "U kopjuan %d rreshta në clipboard",
                    1: "U kojua 1 rresht në clipboard"
                },
                copyTitle: "Kopjo në Clipboard",
                pageLength: {
                    _: "Shfaq %d rreshta",
                    '-1': "Shfaq të gjithë rreshtat",
                    1: "Shfaq 1 rresht"
                }
            },
            emptyTable: "Nuk ka asnjë të dhënë në tabelë",
            info: "Duke treguar _START_ deri _END_ prej _TOTAL_ rreshtave",
            infoEmpty: "Duke treguar 0 deri 0 prej 0 rreshtave",
            infoFiltered: "(të filtruara prej gjithsej _MAX_  reshtave)",
            infoPostFix: " ",
            lengthMen: "Shfaq _MENU_ rreshta",
            loadingRecords: "Po merren te dhënat...",
            searchBuilder: {
                add: "Shto kusht",
                button: {
                    _: "Nderto logjikë kërkimi (%d)",
                    0: "Nderto logjikë kërkimi"
                },
                clearAll: "Pastro të gjitha",
                condition: "Kusht",
                conditions: {
                    array: {
                        contains: "përmban",
                        empty: "bosh",
                        equals: "i\/e barabartë",
                        not: "jo",
                        notEmpty: "jo bosh",
                        without: "pa"
                    },
                    date: {
                        after: "pas",
                        before: "para",
                        between: "ndërmjet",
                        empty: "bosh",
                        equals: "i\/e barabartë",
                        not: "jo",
                        notBetween: "jo ndërmjet",
                        notEmpty: "jo bosh"
                    },
                    number: {
                        between: "ndërmjet",
                        empty: "bosh",
                        equals: "i\/e barabartë\"",
                        gt: "më i\/e madh\/e se",
                        gte: "më i\/e madh\/e ose i\/e barabartë me",
                        lt: "më i\/e vogël se",
                        lte: "më i\/e madh\/e ose i\/e barabartë me",
                        not: "jo",
                        notBetween: "jo ndërmjet",
                        notEmpty: "jo bosh"
                    },
                    string: {
                        contains: "përmban",
                        empty: "bosh",
                        endsWith: "mbaron me",
                        equals: "i\/e barabartë",
                        not: "jo",
                        notEmpty: "jo bosh",
                        startsWith: "fillon me"
                    }
                },
                data: "Të dhëna",
                deleteTitle: "Fshi rregullin e filtrimit",
                logicAnd: "Edhe",
                logicOr: "Ose",
                title: {
                    _: "Logjikë kërkimi (%d)",
                    0: "Logjikë kërkimi"
                },
                value: "Vlera"
            },
            searchPanes: {
                clearMessage: "Pastro të gjitha",
                count: "{total}",
                countFiltered: "{shown} ({total})",
                title: "Filtra aktivë - %d"
            },
            searchPlaceholder: "Kërko",
            select: {
                _: "%d rreshta të zgjedhur",
                1: "1 rresht i zgjedhur",
                cells: {
                    _: "%d qeliza të zgjedhura",
                    1: "1 qelizë e zgjedhur"
                },
                columns: {
                    _: "%d kolona të zgjedhura",
                    1: "1 kolonë e zgjedhur"
                }
            },
        }
        this.dtOptions.language = albanianLanguage;

    }

    // ngAfterViewInit(): void {
    //     this.gridService.table = $('#datatable')

    // }

    constructor(private router: Router, private filtersService: FiltersService, private _notificationService: NotificationService, private gridService: GridService, private userService: UserService) {

    }

    /*
       * type of column
       */
    colType = FieldType
    /*
    * model
    */
    @Input() model: any;

    /*
    * Table filters
    */
    @Input() tableFilters: IForm[];

    /*
    * model
    */
    @Input() columns: IColumns;

    /*
    * show actions or not
    */
    @Input() actions: boolean = true;

    /*
      * show actions or not
      */
    @Input() extraButtons: ButtonOptions[] = [];

    /**
   * gridActions
   */
    @Input() gridActions: ButtonOptions[];
    /*
    * Has filter button
    */
    @Input() hasFilter: boolean = true;
    /*
    * Has edit button
    */
    @Input() hasEdit: boolean;
    /*
    * Has delete button
    */
    @Input() hasDelete: boolean;
    /*
    * Has pdf button
    */
    @Input() hasPdf: boolean;
    /*
    * Grid Title
    */
    @Input() title: string;
    /*
    * route
    */
    @Input() route: string;
    @Input() hideCreateBtn: boolean = false;
    
    /*
    * edit event
    */
    @Output() edit = new EventEmitter();
    /*
    * delete event
    */
    @Output() delete = new EventEmitter();
    /*
    * button clicked event 
    */
    @Output() buttonClicked = new EventEmitter();
    /*
    * button download event 
    */
    @Output() download = new EventEmitter();
    /*
    * button show report event 
    */
    @Output() show = new EventEmitter<{ index: number, row: string }>();
    /*
    * datatable options
    */
    @Input() dtOptions: DataTables.Settings;
    /*
    * button nested event 
    */
    @Output() nestedEvent = new EventEmitter();
    /*
    * button filterdata event 
    */
    @Output() filterData = new EventEmitter();

    /**
     * Edit row method
     * @param row array
     */
    protected editRow(row) {
        this.edit.emit(row);
    }

    /**
    * Edit row method
    * @param row array
    */
    public redirect() {
        this.router.navigate([this.route])
    }

    /**
     * delete row method
     * @param row array
     */
    protected handleClick(event, action) {
        this.buttonClicked.emit({ event, action })
    }

    /**
 * Save row method
 * @param row array
 */
    protected save(filters) {
        this.filterData.emit(filters);
    }


    public display(fieldType: FieldType, row) {
        switch (fieldType) {
            case FieldType.DISPLAY:
                this.nestedEvent.emit(row)
                break;
        }
    }
    public checkForRoles(value: Roles[]) {
        if (value) {
            let found = this.userService.checkForRole(value)
            return found
         
        }
        return true
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.dtOptions?.previousValue) {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.ajax.reload(null, false);
            });
        }
    }
    ngAfterViewInit(): void {
        this.dtTrigger.next();
    }

    ngOnDestroy(): void {
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();
    }

    rerender(data): void {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
            dtInstance.ajax.reload(null, false)
        });
    }
    displayMedics(row) {
        this.nestedEvent.emit(row)
    }


}
