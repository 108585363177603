export enum ControlType {
  input,
  select,
  radio,
  checkbox,
  textarea,
  datepicker,
  multipleSelect,
  headline,
  subHeadline,
  chips,
  break,
  tel,

  pharagraph,
  file,
  tags
}


export enum ModalType {
  input,
  output,
  CancelConfirm
}
