<div class="modal-header justify-content-center">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
        <i class="nc-icon nc-simple-remove" (click)="activeModal.dismiss('Cross click')"></i>
    </button>
    <h4 class="title title-up">{{model.title}}</h4>
</div>

<ng-container [ngSwitch]="model.modalType">

    <ng-container *ngSwitchCase="0" [ngTemplateOutlet]="output" [ngTemplateOutletContext]="{item:item}">
    </ng-container>
    <ng-container *ngSwitchCase="1" [ngTemplateOutlet]="input" [ngTemplateOutletContext]="{item:item}">
    </ng-container>
    <ng-container *ngSwitchCase="2" [ngTemplateOutlet]="title" [ngTemplateOutletContext]="{item:item}">
    </ng-container>
    <ng-container *ngSwitchCase="3" [ngTemplateOutlet]="reason" [ngTemplateOutletContext]="{item:item}">
    </ng-container>
    <ng-container *ngSwitchCase="4" [ngTemplateOutlet]="display" [ngTemplateOutletContext]="{item:item}">
    </ng-container>
    <ng-container *ngSwitchCase="5" [ngTemplateOutlet]="medics" [ngTemplateOutletContext]="{item:item}">

        <!-- <ng-container *ngSwitchCase="controlType.select" [ngTemplateOutlet]="select" [ngTemplateOutletContext]="{item:item}"></ng-container> -->
    </ng-container>
    <ng-container *ngSwitchCase="6" [ngTemplateOutlet]="kmlModal" [ngTemplateOutletContext]="{item:item}">
    </ng-container>


    <ng-template #input let-item='item'>
        <div class="modal-body row">
            <div class="col-12">
                <div class="counter">
                    <circle-progress [percent]="120" [radius]="100" [outerStrokeWidth]="8" [innerStrokeWidth]="2"
                        [outerStrokeColor]="'#78C000'" [innerStrokeColor]="'#C7E596'" [animation]="true"
                        [showUnits]="false" [startFromZero]="false" [showSubtitle]="false" [titleFontSize]="54"
                        [titleFontWeight]="900" [animationDuration]="120000" [responsive]="true"></circle-progress>
                </div>
                <label>PIN:</label>
                <div class="form-group">
                    <input class="form-control" (keyup)='reset($event)' #pin name="pin"
                        oninput="javascript: if (this.value.length > this.maxLength) ;this.value = this.value.slice(0, this.maxLength);"
                        type="number" maxlength="4" />
                    <h6 *ngIf="model.errorLabel" for="pin" class="mt-3" style="color: red !important;font-size: 12px;">
                        *{{model.errorLabel}}</h6>
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <div class="left-side mx-0">
                <button type="button" class="btn btn-default btn-link"
                    (click)="model.handler(modalAction.Cancel)">Anullo</button>
            </div>
            <div class="divider"></div>
            <div class="right-side mx-0">
                <button type="button" class="btn btn-danger btn-link"
                    (click)="model.handler(modalAction.Confirm,pin.value)">Konfirmo</button>
            </div>
        </div>
    </ng-template>

    <ng-template #output>
        <div class="modal-body">
            <p>{{model.body}}</p>
        </div>

        <div class="modal-footer">
            <div class="left-side mx-0">
                <button type="button" class="btn btn-default btn-link"
                    (click)="model.handler(modalAction.Cancel)">Anullo</button>
            </div>
            <div class="divider"></div>
            <div class="right-side mx-0">
                <button type="button" class="btn btn-danger btn-link"
                    (click)="model.handler(modalAction.Confirm)">Konfirmo</button>
            </div>
        </div>
    </ng-template>

    <ng-template #title>
        <div class="modal-body d-flex align-items-center col-12 text-center">
            <p class="d-flex m-auto">{{model.body}}</p>
        </div>
    </ng-template>
    <ng-template #reason let-item='item'>
        <div class="modal-body row vh-25">
            <div class="col-12">
                <label>Arsyeja:</label>
                <div class="form-group">
                    <textarea class="form-control" rows="6" cols="12" id="reason" #reason> </textarea>
                    <h6 *ngIf="model.errorLabel text-danger" for="reason" class="mt-3"
                        style="color: red !important;font-size: 12px;">
                        *Kujdes,arsyeja është fushë e detyrueshme!!</h6>
                </div>
            </div>
        </div>

        <div class="modal-footer">
            <div class="left-side mx-0">
                <button type="button" class="btn btn-default btn-link"
                    (click)="model.handler(modalAction.Cancel)">Anullo</button>
            </div>
            <div class="divider"></div>
            <div class="right-side mx-0">
                <button type="button" class="btn btn-danger btn-link"
                    (click)="model.handler(modalAction.Confirm,reason.value)">Konfirmo</button>
            </div>
        </div>
    </ng-template>
    <ng-template #display let-item='item'>
        <div class="modal-body row vh-25">
            <div class="col-12 d-flex justify-content-between" *ngFor="let data of arrayData">
                <div class="col-md-6">
                    <h6>
                        {{data.name}} {{data.surname}}
                    </h6>
                </div>
                <div class="col-md-6">
                    {{data.status}}
                </div>
            </div>
        </div>

        <div class="modal-footer">

            <!-- <div class="divider"></div> -->
            <div class="center mr-3">
                <button type="button" class="btn btn-primary btn-link"
                    (click)="model.handler(modalAction.Cancel)">Kthehu</button>
            </div>
        </div>
    </ng-template>
    <ng-template #medics let-item='item'>
        <div class="modal-body row vh-25">
            <div class="col-12 d-flex justify-content-between" *ngFor="let data of model.body">
                <div class="col-md-6">
                    <h6>
                        {{data.sectionTitle}}
                    </h6>
                </div>
                <div class="col-md-6">
                    {{data.sectionValue}}
                </div>
            </div>
        </div>

        <div class="modal-footer">

            <!-- <div class="divider"></div> -->
            <div class="center mr-3">
                <button type="button" class="btn btn-primary btn-link"
                    (click)="model.handler(modalAction.Cancel)">Kthehu</button>
            </div>
        </div>
    </ng-template>
    <ng-template #kmlModal let-item='item'>
        <div class="modal-body row vh-25">
            <div class="col-sm-12 ">
                <label>Kml</label>
                <div class="form-group bootstrap-select w-100">
                    <angular2-multiselect (onSelect)="selectKmlEvent($event)" [data]="model.body"
                        [(ngModel)]="selectedItems" [settings]="dropdownSettings">
                    </angular2-multiselect>
                </div>
                <h6 *ngIf="selectedItems.length < 1 && model.errorLabel" for="pin" class="mt-3"
                    style="color: red !important;font-size: 12px;">
                    *Fushe e detyrueshme</h6>
            </div>
        </div>

        <div class="modal-footer">

            <!-- <div class="divider"></div> -->
            <div class="center mr-3">
                <button type="button" class="btn btn-danger btn-link"
                    (click)="model.handler(modalAction.Confirm,selectedItems)">Konfirmo</button>
            </div>
        </div>
    </ng-template>
