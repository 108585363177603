import { UsersModel } from './../../users/users-model';
import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { ROUTES } from '../.././sidebar/sidebar.component';
import { Router, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs/Subscription';
import { AuthenticationService } from 'app/services/authenticationService';
import { GetDataService } from 'app/services/getData.service';
import {DialogComponent, ModalDefinition, ModalType} from '../../components/dialog/dialog.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import jwt_decode from 'jwt-decode';
import Swal from 'sweetalert2';
import {NotificationService, NotificationType} from '../../services/notifications.service';

var misc:any ={
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
}

export class PhysicianData {
    physicianCode: number;
    physicianNid: string;
    physicianFirstName: string;
    physicianLastName: string;
    physicianInstitutionCode: number;
    physicianInstitutionName: string;
    drsksh: string;
    roleId: number;
    healthCenterDetails: [];
}


@Component({
    moduleId: module.id,
    selector: 'navbar-cmp',
    templateUrl: 'navbar.component.html',
    styleUrls: ['navbar.component.scss']
})

export class NavbarComponent implements OnInit{
    private listTitles: any[];
    location: Location;
    private nativeElement: Node;
    private toggleButton;
    private sidebarVisible: boolean;
    private _router: Subscription;
    public open: boolean = false;
    public permissions: any = [];
    public user: UsersModel;
    protected userId: string;
    protected userNid: string;
    public nrReportsWithoutSign: number = 0;
    protected modalRef: any;
    public institutionName: string;
    protected isButton: boolean;
    protected physicianData;
    protected  nameidentifier: any;
    @ViewChild("navbar-cmp", {static: false}) button;

    constructor(public dataService: GetDataService,
                private _notificationService: NotificationService,
                private _service: GetDataService, private _modalService: NgbModal,
                private _auth: AuthenticationService, location: Location,
                private renderer: Renderer2, private element: ElementRef,
                private router: Router) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
    }

    protected getUserById(id: string): Promise<any> {
        return new Promise(
          (resolve, reject) => {
            this.dataService.getDataById('Account/users/me').subscribe(
              (data: any) => {
                resolve(data);
              },
              (error) => {
                reject(error);
              }
            )
          }
        );
      }

      protected getUnsignedReportsNumber(): Promise<any> {
        return new Promise(
          (resolve, reject) => {
            this.dataService.getDataById('IssuedHealthReport/getUnsignedReportCountOfUser').subscribe(
              (data: any) => {
                resolve(data);
              },
              (error) => {
                reject(error);
              }
            )
          }
        );
      }

    async ngOnInit(){
        const token = JSON.parse(localStorage.getItem('currentUser'));
        var userData = (JSON.parse(atob(token.access_token.split('.')[1])))
        this.userId = userData.sub;
        this.user = await this.getUserById(this.userId);
        this.userNid = userData.name;
        this.listTitles = ROUTES.filter(listTitle => listTitle);
        const navbar: HTMLElement = this.element.nativeElement;
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        if (userData.isPhysician === 'True' && userData.hasManyInstitutions === 'False') {
            this.isButton = false;
            this.institutionName = this.user.institutionName;
        } else if (userData.isPhysician === 'True' && userData.hasManyInstitutions === 'True') {
            this.isButton = true;
            this.institutionName = this.user.institutionName;
            this.nameidentifier = userData?.nameidentifier;
        }


        if (body.classList.contains('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        }
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
          const $layer = document.getElementsByClassName('close-layer')[0];
          if ($layer) {
            $layer.remove();
          }
        });
        this.nrReportsWithoutSign = await this.getUnsignedReportsNumber();
    }

    protected getMedicData(nid: string) {
        this._service.getDataById('Interop/getPhysicianDetails?physicianNid=' + nid).subscribe(
            (data: PhysicianData) => {
                if (data) {
                    this.physicianData = data['data'].healthCenterDetails;
                    this.showSwalAlert(); // Call the Swal alert function here
                }
            },
            (error) => {}
        );
    }

    private showSwalAlert() {
        let inputOptions = {
            '': '-- Zgjidhni --' // Empty default value
        };
        if (this.physicianData) {
            this.physicianData.forEach((institution) => {
                inputOptions[institution.physicianInstitutionCode] = institution.physicianInstitutionName;
            });
            Swal.fire({
                title: '<span style="font-size: 22px;">Zgjidhni institucionin</span>',
                input: 'select',
                inputOptions: inputOptions,
                inputLabel: 'Zgjidhni',
                inputValidator: (value) => {
                    return new Promise((resolve) => {
                        if (value) {
                            resolve(null);
                            this.sendSelectedInstitutionCode(value);
                        } else {
                        resolve('Ju duhet te zgjidhni nje institucion'); // Show error message if no value selected
                    }
                    });
                },
                showConfirmButton: true,
                showCloseButton: true,
                allowOutsideClick: true,
                confirmButtonText: 'Ruaj'
            } as any);
        }
    }

    private sendSelectedInstitutionCode(selectedCode: number) {
        if (this.nameidentifier && selectedCode) {
            this._service.addData('Account/userinstitutionFromSso', {
                id: this.nameidentifier,
                institutionCode: selectedCode
            }).subscribe(
                async (data: any) => { // Mark the callback function as async
                    try {
                        this.user = await this.getUserById(this.userId);
                        this.institutionName = this.user.institutionName;
                        window.location.reload();
                    } catch (error) {
                        this._notificationService.showNotification('top', 'right', error.error.message, NotificationType.Error);
                    }
                },
                (error) => {
                    this._notificationService.showNotification('top', 'right', error.error.message, NotificationType.Error);
                }
            );
        }
    }

    openInstitutionPopup() {
        this.getMedicData(this.userNid);
    }

    inArray(permission: any[]) {
        let allowMenuItem: boolean = false;
        if (this.permissions && this.permissions?.length > 0) {
          for (let i = 0; i < permission?.length; i++) {
            if (this.permissions.some(x => x === permission[i]) || permission[i] == '1') {
              allowMenuItem = true;
            }
          }
        }
        return allowMenuItem;
      }

    minimizeSidebar(){
      const body = document.getElementsByTagName('body')[0];

      if (misc.sidebar_mini_active === true) {
          body.classList.remove('sidebar-mini');
          misc.sidebar_mini_active = false;

      } else {
          setTimeout(function() {
              body.classList.add('sidebar-mini');

              misc.sidebar_mini_active = true;
          }, 300);
      }

      // we simulate the window Resize so the charts will get updated in realtime.
      const simulateWindowResize = setInterval(function() {
          window.dispatchEvent(new Event('resize'));
      }, 180);

      // we stop the simulation of Window Resize after the animations are completed
      setTimeout(function() {
          clearInterval(simulateWindowResize);
      }, 1000);
    }

    isMobileMenu(){
        if(window.outerWidth < 991){
            return false;
        }
        return true;
    }

    sidebarOpen(){
        var toggleButton = this.toggleButton;
        var html = document.getElementsByTagName('html')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        },500);
        const mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];
        if (window.innerWidth < 991) {
          mainPanel.style.position = 'fixed';
        }
        html.classList.add('nav-open');
        this.sidebarVisible = true;
    }
    sidebarClose(){
        var html = document.getElementsByTagName('html')[0];
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
        const mainPanel =  <HTMLElement>document.getElementsByClassName('main-panel')[0];

        if (window.innerWidth < 991) {
          setTimeout(function(){
            mainPanel.style.position = '';
          }, 500);
        }
    }
    sidebarToggle(){
        // var toggleButton = this.toggleButton;
        // var body = document.getElementsByTagName('body')[0];
        if(this.sidebarVisible == false){
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }

    getTitle(){
        var titlee = this.getPath();
        if(titlee.charAt(0) === '#'){
            titlee = titlee.slice( 1 );
        }
        for(var item = 0; item < this.listTitles?.length; item++){
            var parent = this.listTitles[item];
            if(parent.path === titlee){
                return parent.title;
            }else if(parent.children){
                var children_from_url = titlee.split("/")[2];
                var parent_from_url = titlee.split("/")[1];
                for(var current = 0; current < parent.children?.length; current++){
                    if(parent.children[current].path === children_from_url && parent.path.startsWith('/'+parent_from_url)){
                        return parent.children[current].title;
                    }
                }
            }
        }
        return '';
    }

    getPath(){
        return this.location.prepareExternalUrl(this.location.path());
    }

    goToProfile(){
      this.router.navigate([`/change-password`]);
    }

    logout() {
        this._auth.logout();
    }

    redirectToNotification(notification) {
        const modal = new ModalDefinition;
        modal.title = notification.title;
        modal.body = notification.content;
        modal.modalType = ModalType.Title;
        this.modalRef = this._modalService.open(DialogComponent);
        this.modalRef.componentInstance.model = modal;
    }
    
    goToNotifications() {
      this.router.navigate([`notifications/list-notifications`]);
    }

    goToManuals() {
      this.router.navigate([`manuals/list-manuals`]);
    }

    reloadPage() {
        const reloadTime = new Date().getTime();
        window.location.href = window.location.href.split('?')[0] + '?r=' + reloadTime;
    }
}


