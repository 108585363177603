
import { Injectable } from "@angular/core";
import jwt_decode from "jwt-decode";

@Injectable({
    providedIn: 'root',
})
export class PermissionService {

    constructor() {}
    hasPermission(permission: string): boolean {
        const tokenPayload: any = this.parseToken();
        if (tokenPayload?.permission && tokenPayload?.permission.length > 0) {
            return (tokenPayload?.permission.find(p => p === permission)) ? true : false;
        }
        return false;
    }

    parseToken() {
        const token: any = JSON.parse(localStorage.getItem('currentUser'));
        return jwt_decode(token.access_token);
    }
}
