import { NotFoundComponent } from './components/not-found/not-found.component';
import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { RoleGuardService as RoleGuard } from './services/role-guard.service';
import { UserProfileComponent } from './user-profile/user-profile.component';

export const AppRoutes: Routes = [

    {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
    },

    {
        path: '',
        component: AdminLayoutComponent,
        children: [
            {
                path: '',
                loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
                canActivate: [RoleGuard],
                data: {
                    expectedRole: ['1']
                }
            },
            {
                path: 'change-password',
                loadChildren: () => import('./user-profile/user-profile.module').then(m => m.UserProfileModule),
                component: UserProfileComponent
            },
            {
                path: 'not-found',
                component: NotFoundComponent,
            },
            {
                path: 'roles',
                loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule),
                canActivate: [RoleGuard],
                data: {
                    expectedRole: ['roles.manage', 'roles.view']
                }
            },
            {
                path: 'institutions',
                loadChildren: () => import('./institutions/institutions.module').then(m => m.InstitutionsModule),
                canActivate: [RoleGuard],
                data: {
                    expectedRole: ['institutions.view']
                }
            },
            {
                path: 'users',
                loadChildren: () => import('./users/users.module').then(m => m.UsersModule),

                canActivate: [RoleGuard],
                data: {
                    expectedRole: ['users.manage', 'users.view']
                }
            },
            {
                path: 'reports',
                loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
                canActivate: [RoleGuard],
                data: {
                    expectedRole: ['issuedhealthreportype.view']
                }
            },
            {
                path: 'pak',
                loadChildren: './pak/pak.module#PakModule',
                canActivate: [RoleGuard],
                data: {
                    expectedRole: ['issuedhealthreportype.view']
                }
            },
            {
                path: 'diagnosis',
                loadChildren: () => import('./diagnosis/diagnosis.module').then(m => m.DiagnosisModule),
                canActivate: [RoleGuard],
                data: {
                    expectedRole: ['diagnose.manage']
                }
            },
            {
                path: 'configurations',
                loadChildren: () => import('./configurations/configurations.module').then(m => m.ConfigurationsModule),
                canActivate: [RoleGuard],
                data: {
                    expectedRole: [1]
                }
            },
            {
                path: 'attestations',
                loadChildren: () => import('./attestations/attestations.module').then(m => m.AttestationsModule),
                canActivate: [RoleGuard],
                data: {
                    expectedRole: [1]
                }
            },
            {
                path: 'logs',
                loadChildren: () => import('./logs/logs.module').then(m => m.LogsModule),
                canActivate: [RoleGuard],
                data: {
                    expectedRole: [1]
                }
            },
            {
                path: 'statistics',
                loadChildren: () => import('./statistics/statistics.module').then(m => m.StatisticsModule),
                canActivate: [RoleGuard],
                data: {
                    expectedRole: ['customreports.manage']
                }
            },
            {
                path: 'notifications',
                loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule),
                canActivate: [RoleGuard],
                data: {
                    expectedRole: ['notification.edit', 'notification.create', 'notification.delete', 'notification.view']
                }
            },
            {
                path: 'manuals',
                loadChildren: () => import('./manuals/manuals.module').then(m => m.ManualsModule),
                canActivate: [RoleGuard],
                data: {
                    expectedRole: ['1']
                }
            },
            {
                path: 'contact',
                loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule),
                canActivate: [RoleGuard],
                data: {
                    expectedRole: ['send.email']
                }
            },
            {
                path: 'audit',
                loadChildren: () => import('./audit/audit.module').then(m => m.AuditModule),
                canActivate: [RoleGuard],
                data: {
                    expectedRole: ['issuedhealthreportype.audit']
                }
            },
            {
                path: 'e-albania',
                loadChildren: () => import('./e-albania-notification/e-albania-notification.module').then(m => m.EAlbaniaNotificationModule),
                canActivate: [RoleGuard],
                data: {
                    expectedRole: ['issuedhealthreportype.audit']
                }
            },
        ]
    }, {
        path: '',
        component: AuthLayoutComponent,
        children: [
            {
                path: 'api',
                loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
            },
            {
                path: 'auth',
                loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
            },

        ]
    },

]
