export const environment = {
    apiVersion: '1.0',
    // baseUrl:'https://192.168.2.100:44302/',
    // apiUrl: 'https://192.168.2.100:44302/api/'
     baseUrl: 'https://eraporte.gov.al/',
     apiUrl: 'https://eraporte.gov.al/api/'
    //  baseUrl: 'https://localhost:44350/',
    //  apiUrl: 'https://localhost:44350/api/'

}
