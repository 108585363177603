import {Component, OnInit} from '@angular/core';
import {BnNgIdleService} from 'bn-ng-idle';
import {AuthenticationService} from './services/authenticationService';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  constructor(private bnIdle: BnNgIdleService, private _auth: AuthenticationService) {}

  ngOnInit() {
    this.bnIdle.startWatching(3600).subscribe((isTimedOut: boolean) => {
      const currentUser = this._auth.currentUserValue;
      const isLoggedIn = currentUser && currentUser.access_token;
      if (isTimedOut && isLoggedIn) {
        this.showSwalAlert();
      }
    });

  }

  private showSwalAlert() {
      let timerInterval;
      Swal.fire({
        title: '<span style="font-size: 22px;">Dëshironi të vazhdoni të qëndroni në sistem?</span>',
        html: "Sistemi do të mbyllet për <b></b> sekonda.",
        icon: "warning",
        showCloseButton: true,
        timer: 20000,
        timerProgressBar: true,
        didOpen: () => {
          Swal.showLoading();
          const timer = Swal.getPopup().querySelector("b");
          timerInterval = setInterval(() => {
            timer.textContent = `${Math.ceil(Swal.getTimerLeft() / 1000)}`; // Display seconds left
          }, 1000); // Change interval to 1000 milliseconds (1 second)
        },
        willClose: () => {
          clearInterval(timerInterval);
        }
      } as any).then((result) => {
        if (result.isConfirmed) {
          this._auth.logout();
        } else if (result.dismiss === Swal.DismissReason.timer) {
          this._auth.logout(); // Logout if timer finishes
        }
      });
  }
}
